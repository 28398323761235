import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';
import TWEEN from 'tween';
export default class Tjs {
  constructor(divid) {
    this._init(divid);
  }

  _init(div) {
    this._initContainer(div);
    this._initData();
    this._initScene();
    this._initCamera();
    this._initRender();
    this._initControls();
    this._initLight();
    this.animates(); //循环渲染
  }

  //初始化变量
  _initData() {
    this.renderID = null;
    this.clickGroup = [];
  }

  _initContainer(divID) {
    const container = document.getElementById(divID);
    return (this.container = container);
  }

  _initScene() {
    const scene = new THREE.Scene();
    return (this.scene = scene);
  }

  _initCamera() {
    const camera = new THREE.PerspectiveCamera(
      60,
      this.container.offsetWidth / this.container.offsetHeight,
      1,
      10000
    );

    camera.position.set(10, 10, 10);

    return (this.camera = camera);
  }

  _initRender() {
    const renders = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
			logarithmicDepthBuffer:true,
      outputEncoding:2.2
    });
		
		renders.shadowMap.enabled = true
		renders.shadowMap.type = THREE.PCFSoftShadowMap
		
    renders.setSize(this.container.offsetWidth, this.container.offsetHeight);
    renders.setPixelRatio(window.setPixelRatio);
    this.container.appendChild(renders.domElement);
    return (this.renders = renders);
  }

  _initControls() {
    const control = new OrbitControls(this.camera, this.renders.domElement);
    return (this.control = control);
  }

  _initLight() {
    const amblight = this.addLight();
    this.scene.add(amblight);
    return (this.amblight = amblight);
  }

  statsShow() {
    const state = new Stats();
    state.dom.style.position = 'absolute';
    this.container.appendChild(state.dom);
    return (this.state = state);
  }

  animates() {
    this.renderID = requestAnimationFrame(this.animates.bind(this));
    if (this.state) {
      this.state.update();
    }
    if (!this.composer) {
      this.renders.clear();
      this.renders.render(this.scene, this.camera);
    } else {
      this.composer.render();
      // console.log(1);
    }
    if (this.update) {
      this.update();
    }
  }

  renderResize() {
    this.camera.aspect = this.container.offsetWidth / this.container.offsetHeight;
    this.camera.updateProjectionMatrix();

    this.renders.setSize(this.container.offsetWidth, this.container.offsetHeight);

    if (this.composer) {
      this.composer.setSize(this.container.offsetWidth, this.container.offsetHeight);
    }
  }

  //场景销毁
  destroy() {
    if (this.NoCompleteText) {
      this.container.removeChild(this.NoCompleteText);
      this.NoCompleteText = null;
    }

    cancelAnimationFrame(this.renderID);
    this.scene.traverse((child) => {
      if (child.geometry) {
        child.geometry.dispose();
      }

      if (child.material) {
        child.material.dispose();
      }
      child = null;
    });

    this.scene.clear();
    this.scene = null;
    this.container.removeChild(this.renders.domElement);
    if (this.state) {
      this.container.removeChild(this.state.dom);
      this.state = null;
    }
    this.renders.clear();
    this.renders.dispose();
    this.renders.forceContextLoss();
    this.renders = null;
    this.camera = null;
    this.container = null;
  }

  axes(length = 10) {
    const axesHelper = new THREE.AxesHelper(length);
    this.scene.add(axesHelper);
  }

  addLight(type = 'all', color = 0xffffff, intensity = 0.5) {
    let light;
    if (type == 'all') {
      light = new THREE.AmbientLight(color, intensity);
    } else if (type == 'spot') {
      light = new THREE.SpotLight(color, intensity);
    } else if (type == 'direction') {
      light = new THREE.DirectionalLight(color, intensity);
    } else if (type == 'point') {
      light = new THREE.PointLight(color, intensity, 100);
    }

    return light;
  }

  loadSkyBox(uri, type = 'png') {
    const skyCubeTexture = new THREE.CubeTextureLoader()
      .setPath(uri)
      .load([`px.${type}`, `nx.${type}`, `py.${type}`, `ny.${type}`, `pz.${type}`, `nz.${type}`],function(texture){
        texture.encoding = THREE.sRGBEncoding;
      });
    return skyCubeTexture;
  }

  loadTexture_Y(fileUrl) {
    return new Promise((r) => {
      new THREE.TextureLoader().load(fileUrl, (texture) => {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        r(texture);
      });
    });
  }

  loadTexture(uri) {
    return new THREE.TextureLoader().load(uri, (map) => {
      map.wrapS = map.wrapT = THREE.RepeatWrapping;
      map.encoding = THREE.sRGBEncoding;
    });
  }

  setMaterial(type = 'standard', params) {
    let material;
    if (type == 'standard') {
      material = new THREE.MeshStandardMaterial(params);
    } else if (type == 'shader') {
      material = new THREE.ShaderMaterial(params);
    } else if (type == 'basic') {
      material = new THREE.MeshBasicMaterial(params);
    }

    if (material) {
      return material;
    }
  }

  //创建2套UV(AoMap)
  CreateUV2(geometry) {
    geometry.setAttribute('uv2', new THREE.BufferAttribute(geometry.attributes.uv.array, 2));
  }

  matSide() {
    return THREE.DoubleSide;
  }

  //初始化导出插件的下载按钮
  initExport() {
    this.downloadLink = document.createElement('a');
    this.downloadLink.style.display = 'none';
  }

  //gltf模型导出器(第三个属性值为导出的gltf模型格式false为gltf若为true则为glb)
  glTF_Export(inputModel, filename = 'model', type = false) {
    const options = {
      trs: false,
      onlyVisible: true,
      truncateDrawRange: true,
      binary: type
      // maxTextureSize: 4096,
      // embedImages: false,
    };

    const exporter = new GLTFExporter();
    exporter.parse(
      inputModel,
      (result) => {
        if (result instanceof ArrayBuffer) {
          this.saveArrayBuffer(result, `${filename}.glb`);
        } else {
          const output = JSON.stringify(result, null, 2);
          // console.log(output, '输出值');
          this.saveString(output, `${filename}.gltf`);
        }
      },
      (error) => {
        console.log('导出错误', error);
      },
      options
    );
  }

  saveString(text, filename) {
    this.saveDownLoad(new Blob([text], { type: 'text/plain' }), filename);
  }

  saveArrayBuffer(buffer, filename) {
    this.saveDownLoad(new Blob([buffer], { type: 'application/octet-stream' }), filename);
  }

  vec2(x, y) {
    return new THREE.Vector2(x, y);
  }

  vec3(x, y, z) {
    return new THREE.Vector3(x, y, z);
  }

  saveDownLoad(blob, filename) {
    this.downloadLink.href = URL.createObjectURL(blob);
    this.downloadLink.download = filename;
    this.downloadLink.click();
  }

  //外部加载
  ModelLoaderFrom(filename, data) {
    return new Promise((resolve) => {
      let loader;
      if (filename.indexOf('.fbx') > -1 || filename.indexOf('.FBX') > -1) {
        loader = new FBXLoader(new THREE.LoadingManager());
        let loadmode = loader.parse(data);
        resolve(loadmode);
      } else if (filename.indexOf('.gl') > -1) {
        loader = new GLTFLoader();
        loader.parse(data, '', (result) => {
          resolve(result.scene);
        });
      }
    });
  }

  //加载模型
  ModelLoad(uri, func) {
    if (uri.indexOf('.gl') > -1) {
      new GLTFLoader().load(uri, (model) => {
        if (func) {
          func(model);
        }
      });
    } else if (uri.indexOf('.fbx') > -1 || uri.indexOf('.FBX') > -1) {
      new FBXLoader().load(uri, (model) => {
        if (func) {
          func(model);
        }
      },function(xhr){
				// console.log(xhr)
					// console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
			});
    }
  }

  addPlane(width = 10, height = 10, segwidth = 1, segheight = 1) {
    const geometry = new THREE.PlaneBufferGeometry(width, height, segwidth, segheight);
    return geometry;
  }

  setMesh(geometry, material) {
    const mesh = new THREE.Mesh(geometry, material);
    return mesh;
  }

  setColor(color) {
    return new THREE.Color(color);
  }

  NoComplete() {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.display = 'flex';
    div.style.width = '100%';
    div.style.color = 'white';
    div.style.left = '25%';
    div.style.top = '50%';
    div.style.fontSize = '80px';
    // const h1 = document.createElement("h1");
    // h1.style.innerText = "未完待续";
    // div.appendChild(h1);
    div.style.textAlign = 'center';
    div.innerText = '未完待续...';

    this.NoCompleteText = div;
    this.container.appendChild(div);
  }

  initRayCaster() {
    this.raycaster = new THREE.Raycaster();
  }

  initMouse() {
    this.mouse = new THREE.Vector2();
  }

  //点击模式
  clickMode() {
    this.initRayCaster();
    this.initMouse();
  }

  //鼠标移动事件
  onMouseMove(event) {
    // this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    // this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    const px = this.container.getBoundingClientRect().left;
    const py = this.container.getBoundingClientRect().top;

    this.mouse.x = (event.clientX / (this.renders.domElement.clientWidth - px)) * 2 - 1;
    this.mouse.y = -(event.clientY / (this.renders.domElement.clientHeight - py)) * 2 + 1;
  }

  //点击射线更新事件
  ClickUpdate(func) {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    // 计算物体和射线的焦点
    const intersects = this.raycaster.intersectObjects(this.clickGroup);
    // console.log(intersects, "结果");
    if (intersects.length > 0) {
      const clickObject = intersects[0];
      if (func) {
        func(clickObject);
      }
    }
  }

  usePass() {
    this.composer = new EffectComposer(this.renders);
  }

  useRenderPass() {
    const renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderPass);
  }

  useOutLine() {
    this.outlinePass = new OutlinePass(
      new THREE.Vector2(this.container.offsetWidth, this.container.offsetHeight),
      this.scene,
      this.camera
    );

    this.outlinePass.edgeStrength = 3;
    this.outlinePass.edgeGlow = 1;
    this.outlinePass.edgeThickness = 1;
    this.outlinePass.pulsePeriod = 2;
    // this.outlinePass.usePatternTexture = false;

    this.composer.addPass(this.outlinePass);
  }

  useFXAA() {
    const effects = new ShaderPass(FXAAShader);
    effects.uniforms['resolution'].value.set(
      1 / this.container.offsetWidth,
      1 / this.container.offsetHeight
    );
    // this.composer.addPass(effects);
  }

  sunAndCloud() {
    this.NoComplete();
  }

  flyTo(option) {
    option.position = option.position || []; // 相机新的位置
    option.control = option.control || []; // 控制器新的中心点位置(围绕此点旋转等)
    option.duration = option.duration || 1000; // 飞行时间
    option.easing = option.easing || TWEEN.Easing.Linear.None;
    TWEEN.removeAll();
    const curPosition = this.camera.position;
    const controlsTar = this.control.target;
    const m = {
      x1: curPosition.x, // 相机当前位置x
      y1: curPosition.y, // 相机当前位置y
      z1: curPosition.z, // 相机当前位置z
      x2: controlsTar.x, // 控制当前的中心点x
      y2: controlsTar.y, // 控制当前的中心点y
      z2: controlsTar.z // 控制当前的中心点z
    };
    const tween = new TWEEN.Tween(m)
      .to(
        {
          x1: option.position[0], // 新的相机位置x
          y1: option.position[1], // 新的相机位置y
          z1: option.position[2], // 新的相机位置z
          x2: option.control[0], // 新的控制中心点位置x
          y2: option.control[1], // 新的控制中心点位置x
          z2: option.control[2] // 新的控制中心点位置x
        },
        option.duration
      )
      .easing(TWEEN.Easing.Linear.None); // TWEEN.Easing.Cubic.InOut //匀速
    tween.onUpdate(() => {
      this.control.enabled = false;
      this.camera.position.set(m.x1, m.y1, m.z1);
      this.control.target.set(m.x2, m.y2, m.z2);
      this.control.update();
      if (option.update instanceof Function) {
        option.update(tween);
      }
    });
    tween.onStart(() => {
      this.control.enabled = false;
      if (option.start instanceof Function) {
        option.start();
      }
    });
    tween.onComplete(() => {
      this.control.enabled = true;
      if (option.done instanceof Function) {
        option.done();
      }
    });
    tween.start();
    TWEEN.add(tween);
    return tween;
  }
}
