<template>
	<div class="wrapper">
		<div id="guid" v-show="false"></div>
		<div id="compass">
			<div id="east" @click="changeCompassCamera('东')"></div>
			<div id="south" @click="changeCompassCamera('南')"></div>
			<div id="west" @click="changeCompassCamera('西')"></div>
			<div id="north" @click="changeCompassCamera('北')"></div>
		</div>
		<div id="build"></div>
		<div class="controlBox">
			<div style="width: 40%"></div>
			<div style="width: 20%; font-size: 20px; color: #fff">
				科大实践基地二期<span v-if="numFlag">数字工地</span>
			</div>
			<div style="width: 40%" class="mainContr" v-show="mainContr&&isNumWork">
				<!-- <div>项目概况</div>
          <div>整体布局</div> -->
				<div @click="changeFloor(1)" v-show="backHomeFlag">
					返回全景
				</div>
				<div @click="changeFloor(2)" v-show="!backHomeFlag">
					查看构造
				</div>
				<div @click="hideRound" v-show="!hideRoundFlag">隐藏环境</div>
				<div @click="hideRound" v-show="hideRoundFlag">显示环境</div>
				<div @click="changeSky" v-show="sky == 'day'">夜晚</div>
				<div @click="changeSky" v-show="sky == 'night'">白天</div>
			</div>
		</div>
		<div v-if="numFlag" class="homePageContr homePageControl">
			<!-- <div @click="backHomePage" class="backHome">
				<img src="../assets/images/home.svg" alt="" /><span>返回主页</span>
			</div> -->
			<div class="floorCut">
				<div class="floorCeng" style="color: #fff">
					<div @click="clickVideo(2)">现场西南角</div>
					<div @click="clickVideo(1)">现场东南角</div>
					<div @click="clickVideo(3)">西侧道路</div>
					<div @click="clickVideo(4)">东侧道路</div>
					<div @click="clickVideo(5)">塔吊监控</div>
				</div>
			</div>
		</div>
		<div v-if="mainContr" class="homePageContr">
			<div @click="backHomePage" class="backHome">
				<img src="../assets/images/home.svg" alt="" /><span>返回主页</span>
			</div>
			<div class="floorCut" v-if="buildFoolerCon">
				<p @click="showFloor">
					<img src="../assets/images/build.svg" style="
              vertical-align: middle;
              margin-top: 4px;
              width: 24px;
              margin-bottom: 20px;
            " alt="" />
				</p>
				<div class="floorCeng" style="color: #fff">
					<div @click="focusFloor(1)">1层</div>
					<div @click="focusFloor(2)">2层</div>
					<div @click="focusFloor(3)">3层</div>
					<div @click="focusFloor(4)">4层</div>
					<div @click="focusFloor(5)">5层</div>
					<div @click="focusFloor(6)">6层</div>
					<div @click="focusFloor(7)">7层</div>
				</div>
			</div>
		</div>
		<!-- 首次进入页面按钮及描述 -->
		<div class="firstLeft" v-show="!mainContr">
			<div class="info1">
				<div>
					<p class="titleBox">
						<img style="width: 16px" src="../assets/images/dInfo.svg" alt="" /><span>详细信息</span>
					</p>
					<div class="infoListBox">
						<span class="cle"><i class="el-icon-s-flag"></i></span><span class="discTitle">地理位置:</span><span
							class="dis">海淀区学院路30号</span>
					</div>
					<div class="infoListBox">
						<span class="cle"><i class="el-icon-receiving"></i></span><span
							class="discTitle">建筑面积:</span><span class="dis">约14344.87平方米</span>
					</div>
					<div class="infoListBox">
						<span class="cle"><i class="el-icon-s-data"></i></span><span class="discTitle">建筑高度:</span><span
							class="dis">约31.2米</span>
					</div>
					<div class="infoListBox">
						<span class="cle"><i class="el-icon-film"></i></span><span class="discTitle">建筑楼层:</span><span
							class="dis">地上6层，地下2层</span>
					</div>
				</div>
			</div>
			<div class="info2">
				<p class="titleBox">
					<span style="line-height: 17px; margin-left: 0"
						class="el-icon-notebook-2"></span><span>项目概况</span><span><img @click="intoPlayDisc"
							style="cursor: pointer; width: 20px; height: 20px" src="../assets/images/video.svg"
							alt="" /></span>
				</p>
				<div class="content">
					<div class="itemInfoContent" style="line-height: 22px">
						由中建八局承建的北京科技大学工程实践基地二期（2#实验楼）项目位于北京市海淀区学院路30号，
						地上6层，地下2层，总建筑高度31.2m，总建筑面积14344.87㎡。
						本项目属于校园内施工，现场场地狭小、噪音及扬尘的管控都成为项目施工的重要难点。
						BIM的全周期应用、智能化管理都对施工难点起到了有效的管控及辅助作用。
						工程实践基地二期（2#实验楼）项目建设，使北京科技大学的教学水平有了进一步的提升，
						人才培养有了更广阔的学习空间，也为大学的发展打开了崭新的篇章
					</div>
				</div>
			</div>
		</div>
		<div class="firstRight" v-show="!mainContr">
			<div class="btn" @click="itemTotal">项目概况</div>
			<div class="btn" @click="palyWorkVideo">进度模拟</div>
			<div class="btn" @click="intoNumWorkPlace">数字工地</div>
		</div>
		<!-- 首次进入页面按钮及描述 -->
		<div class="videoWorkBox" v-if="isShowVideo">
			<video id="my_video_1" class="videoPlayer" width="400" height="240" preload="auto" controls>
			</video>
		</div>

		<canvas id="canvas" style="display: none;">你的浏览器不支持canvas</canvas>
		<div class="loading" v-if="loading">
			<img class="loadImg"style="width: 46px" src="../assets/images/loading.svg"alt=""/>
		</div>
	</div>
</template>

<script>
	let builds;
	let Guids;
	let InnerBuilding; //结构楼
	let mainBuilding; //主楼
	let mainBuild1;//壳子
	let Crane; //塔吊
	let terrain; //地形
	let tree; // 树
	let lightsd; //灯
	// let wall;//围墙
	// let wall1;
	// let wallOut;//围墙LR
	// let wallLeft;
	// let wallRight;
	let treeObj;
	let treeModels;
	let plane;
	let light;
	let directionalLight;
	let floorArray = [];
	let sky1, sky2;
	let start = 0;
	let final = 500;
	let open = false;
	let video;//播放视频监控容器
	let hls;//海康对象插件
	let pointLight = [];
	// let cameraModel; //摄像机
	// let cameraModel2;
	import Tjs from "../script/T_Scene";
	import * as THREE from "three";
	import TWEEN from "tween";
	import {
		DirectionalLight,
		HemisphereLight
	} from "three";
	export default {
		name: "",
		components: {},
		props: {},
		data() {
			return {
				sky: "night",
				playEndStatus: false,
				buildFoolerCon: false,
				mainContr: false,
				floorCeng: false,
				hideRoundFlag: false,
				backHomeFlag: false,
				isShowVideo: false,
				numFlag: false,
				isNumWork: true,
				loading:false
			};
		},
		methods: {
			palyWorkVideo() {
				this.$router.push({
					name: "WorkPress"
				});
			},
			intoPlayDisc() {
				this.$router.push({
					name: "DisVideo",
					query: {
						time: new Date().getTime()
					},
				});
			},
			itemTotal() {
				this.mainContr = true;
				this.hideRoundFlag = false;
				this.changeSky();
				setTimeout(() => {
					this.changeSky();
				}, 0);
			},
			intoNumWorkPlace() {
				this.sky = 'night'
				this.changeSky();
				this.numFlag = true
				this.isNumWork = false
				// builds.outlinePass.selectedObjects = [];
				InnerBuilding.model.visible = true;
				mainBuilding.model.visible = false;
				// mainBuild1.model.visible = false;
				terrain.model.visible = false;
				plane.model.visible = true;
				tree.model.visible = false;
				Crane.model.visible = true;
				lightsd.model.visible = false;
				// wall.model.visible = true;
				// wallOut.visible = true;
				// wallLeft.visible = true;
				// wallRight.visible = true;
				// cameraModel.model.visible = true;
				// cameraModel2.model.visible = true;
				this.backHomeFlag = true
				this.mainContr = true;
				this.changeCompassCamera("西");
				builds.scene.traverse((child) => {
					if (child.isMesh) {
						if (child.name == '1' || child.name == '2' || child.name == '3' || child.name == '4' ||
							child.name == '5') {
							child.visible = true
						}
					}
				})
			},
			initValue() {
				mainBuilding = {
					model: null,
					visible: true,
					materialGroup: [],
				};
				// mainBuild1 = {
				// 	model: null,
				// 	visible: true,
				// 	materialGroup: [],
				// };
				InnerBuilding = {
					model: null,
					visible: false,
					materialGroup: [],
				};

				Crane = {
					model: null,
					visible: false,
					materialGroup: [],
				}

				terrain = {
					model: null,
					visible: false,
					materialGroup: [],
				};

				plane = {
					model: null,
					visible: false,
					materialGroup: [],
				};

				tree = {
					model: null,
					visible: false,
					materialGroup: [],
				};
				
				// wall = {
				// 	model: null,
				// 	visible: false,
				// 	materialGroup: [],
				// };
				
				// wall1 = {
				// 	model: null,
				// 	visible: false,
				// 	materialGroup: [],
				// };
				
				// wallOut = {
				// 	model: null,
				// 	visible: false,
				// 	materialGroup: [],
				// };
				
				// wallLeft = {
				// 	model: null,
				// 	visible: false,
				// 	materialGroup: [],
				// };
				
				// wallRight = {
				// 	model: null,
				// 	visible: false,
				// 	materialGroup: [],
				// };
				
				treeObj = {
					model: null,
					visible: false,
					materialGroup: [],
				}
				treeModels = {
						tree1: {
								obj: "./build/treeObj/songshu.fbx",
								mesh: null,
								visible: false,
						},
						tree2: {
								obj: "./build/treeObj/songshu.fbx",
								mesh: null,
								visible: false,
						},
						tree3: {
								obj: "./build/treeObj/songshu.fbx",
								mesh: null,
								visible: false,
						},
						tree4: {
								obj: "./build/treeObj/songshu.fbx",
								mesh: null,
								visible: false,
						},
						tree5: {
								obj: "./build/treeObj/songshu.fbx",
								mesh: null,
								visible: false,
						},
						tree6: {
								obj: "./build/treeObj/songshu.fbx",
								mesh: null,
								visible: false,
						},
				}
				
				lightsd = {
					model: null,
					visible: false,
					materialGroup: [],
				};
				// cameraModel = {
				// 	model: null,
				// 	visible: false,
				// 	materialGroup: [],
				// };

				// cameraModel2 = {
				// 	model: null,
				// 	visible: false,
				// 	materialGroup: [],
				// };
			},
			resizes() {
				builds.renderResize();
				// Guids.renderResize();
			},
			sceneModel() {
				// Guids.ModelLoad('./NS2.fbx', (object) => {
				//   object.traverse((child) => {
				//     if (child.isMesh) {
				//       child.material = Guids.setMaterial('basic', {
				//         map: Guids.loadTexture('WASD.png')
				//       });
				//       Guids.clickGroup.push(child);
				//     }
				//   });
				//   Guids.scene.add(object);
				// });

				builds.ModelLoad("./building/mian10.FBX", (object) => {

					object.traverse((child) => {
						if (child.isGroup) {
							if (child.name == "visibles") {
								mainBuilding.model = child;
							}	
						}
						if (child.isMesh) {
							builds.clickGroup.push(child);
							child.castShadow = true;
							child.receiveShadow = true
							child.material = builds.setMaterial("standard", {
								color: child.material.color,
								map: child.material.map,
								transparent: child.material.transparent,
								opacity: child.material.opacity,
							});
							mainBuilding.materialGroup.push({
								mat: child.material,
								defaultOpacity: child.material.opacity,
								transparent: child.material.transparent,
							}); 
							child.material.color.convertSRGBToLinear()
						}
						
					});
					// mainBuilding.model = object;
					// object.scale.setScalar(10000);
					builds.scene.add(object);
				});

				
				// const geometry = new THREE.PlaneBufferGeometry(90, 10);
				// const geometry1 = new THREE.PlaneBufferGeometry(70, 10);
				// const texture = new THREE.TextureLoader().load('./building/WeiQiang.jpg')
				// texture.wrapS = 1001;	
			 //  texture.wrapT = 1001;	
			 //  texture.repeat.set(1, 1); //贴图x,y平铺数量
				// const material = new THREE.MeshBasicMaterial({
				// 	color: "rgb(255, 255, 255)",
				// 	side: THREE.DoubleSide,
				// 	map:texture
				// });
				// wall.model = new THREE.Mesh(geometry, material);
				// builds.scene.add(wall.model);
				// 地形
				builds.ModelLoad("./building/plane.FBX", (object) => {
					plane.model = object;
					// object.scale.setScalar(0.01);
					plane.model.visible = false;
					// plane.model.position.set(-260, 0, 920);
					// plane.model.rotation.set(0, -Math.PI, 0);
					object.traverse(child=>{
						if(child.isMesh){
							child.material.color.convertSRGBToLinear();
						}
					})
					builds.scene.add(plane.model);
				});
				builds.ModelLoad("./building/ceng3.fbx", (object) => {
					object.traverse((child) => {
						if (child.isMesh) {
							child.castShadow = true;
							child.receiveShadow = true
							if(child.material.map) child.material.map.anisotropy = 16
							floorArray.push(child);
							InnerBuilding.materialGroup.push({
								mat: child.material,
								defaultOpacity: child.material.opacity,
								transparent: child.material.transparent,
							});
							child.material.color.convertSRGBToLinear();
						}
					});

					InnerBuilding.model = object;
					object.scale.setScalar(0.01);
					InnerBuilding.model.visible = false;
					InnerBuilding.model.position.set(0, 14, 0);
					builds.scene.add(InnerBuilding.model);
				});
				// 塔吊
				builds.ModelLoad("./building/crane.FBX", (object) => {

					Crane.model = object;
					// object.scale.setScalar(0.01);
					Crane.model.visible = false;
					Crane.model.position.set(-260, 0, 920);
					Crane.model.rotation.set(0, -Math.PI, 0);
					// Crane.model.traverse((obj) => {
					// 	if (obj.isMesh && obj.name == 'obj1') {
					// 		this.createTag(obj, 'Crane', 3);
					// 	}
					// })
					builds.scene.add(Crane.model);
				});
				
				// 摄像机
				// builds.ModelLoad("./building/camera.gltf", (object) => {
				// 	cameraModel.model = object.scene;
				// 	cameraModel.model.scale.setScalar(0.3);
				// 	cameraModel.model.position.set(-38, 0, 34);
				// 	builds.scene.add(cameraModel.model);
				// 	cameraModel.model.traverse((obj) => {
				// 		if (obj.name === "pCylinder6_1" || obj.name === "pCylinder6") {
				// 			this.createTag(obj, obj.name, 1);
				// 			// builds.clickGroup.push(obj);
				// 		}

				// 	});
				// 	cameraModel.model.visible = false;
				// });

				// builds.ModelLoad("./building/camera.gltf", (object) => {
				// 	cameraModel2.model = object.scene;
				// 	cameraModel2.model.position.set(46, 0, 13);
				// 	cameraModel2.model.scale.setScalar(0.3);
				// 	cameraModel2.model.rotation.set(0, Math.PI, 0);
				// 	builds.scene.add(cameraModel2.model);
				// 	cameraModel2.model.traverse((obj) => {
				// 		if (obj.name === "pCylinder6_1" || obj.name === "pCylinder6") {
				// 			this.createTag(obj, obj.name, 2);
				// 			// builds.clickGroup.push(obj);
				// 		}
				// 	});
				// 	cameraModel2.model.visible = false;
				// });
				
				
				builds.ModelLoad("./building/pei.FBX", (object) => {
					object.scale.setScalar(100);
					// object.traverse(child=>{
					// 	if(child.isMesh){
					// 		console.log(child)
					// 		if(child.name.indexOf('草地')>-1){
					// 			child.material.color.convertSRGBToLinear();
					// 		}
							
					// 	}
					// })
					builds.scene.add(object);
					terrain.model = object;
				});


				builds.ModelLoad("./building/treecomb.fbx", (object) => {
					tree.model = object;
					object.traverse((child) => {
						if (child.isMesh) {
							child.material[0] = builds.setMaterial("standard", {
								map: builds.loadTexture("./building/treetex/shu02.png"),
								transparent: true,
								color:"rgb(56,94,15)"
							});
							child.material[0].color.convertSRGBToLinear();
							child.renderOrder = 2;
							child.material[1] = builds.setMaterial("standard", {
								map: builds.loadTexture("./building/treeObj/AlaskaCedarBark.png"),
								color:"rgb(163,148,128)"
							});
							child.material[1].color.convertSRGBToLinear();
						}
					});
					object.scale.setScalar(100);
					builds.scene.add(object);
					// this.loading = false
				});
				builds.ModelLoad("./building/lightsd.fbx", (object) => {
					lightsd.model = object;
					object.traverse((child) => {
						if (child.isGroup) {
							if (child.name == "lit") {
								const light = builds.addLight("point", 0xffffff, 0);
								// const value = 1;
								// light.position.set(
								//   child.position.x * value,
								//   child.position.y * value,
								//   child.position.z * value
								// );
								light.position.copy(child.position);
								// const helper = new THREE.PointLightHelper(light,0.1);
								// child.parent.add(helper);
								pointLight.push(light);

								child.parent.add(light);
								// builds.scene.add(light);
							}
						}
										
					});
					object.scale.setScalar(100);
					builds.scene.add(object);
				});
			},
			changeView(name) {
				const distance = builds.camera.position.distanceTo(builds.control.target);

				let pos = {
					x: builds.control.target.x,
					y: builds.control.target.y,
					z: builds.control.target.z,
				};
				if (name == "top") {
					pos.y += distance;
				} else if (name == "down") {
					pos.y -= distance;
				} else if (name == "W") {
					//西
					pos.z -= distance;
				} else if (name == "S") {
					//南
					pos.x -= distance;
				} else if (name == "E") {
					//东
					pos.z += distance;
				} else if (name == "N") {
					//北
					pos.x += distance;
				}

				const m = {
					x: builds.camera.position.x,
					y: builds.camera.position.y,
					z: builds.camera.position.z,
				};

				const tws = new TWEEN.Tween(m);
				tws.to({
					x: pos.x,
					y: pos.y + 10,
					z: pos.z
				}, 1000);
				tws.onUpdate(() => {
					builds.camera.position.set(m.x, m.y, m.z);
					builds.camera.lookAt(builds.control.target);
				});
				tws.start();
			},
			createTag(mesh, round, position) {
				let url1 = require('../assets/images/camera.png')
				//将图片构建到纹理中
				let geometry1 = new THREE.PlaneGeometry(4, 3)
				let texture = THREE.ImageUtils.loadTexture(url1, null, function(t) {})

				let material1 = new THREE.MeshBasicMaterial({
					map: texture,
					side: THREE.DoubleSide,
					opacity: 1,
					transparent: true,
				})

				let rect = new THREE.Mesh(geometry1, material1)

				if (position == 1) {
					if (round == 'pCylinder6_1') {
						rect.position.set(-38, 8, 32)
						rect.rotation.set(0, 0, Math.PI / 8);
						rect.name = '1'
					} else {
						rect.position.set(-37, 8, 36)
						rect.rotation.set(0, 0, -Math.PI / 8);
						rect.name = '2'
					}
				} else if (position == 2) {
					if (round == 'pCylinder6_1') {
						rect.position.set(47, 8, 13)
						rect.rotation.set(0, 0, -Math.PI / 8);
						rect.name = '3'
					} else {
						rect.position.set(44, 8, 11)
						rect.rotation.set(0, Math.PI / 16, 0);
						rect.name = '4'
					}
				} else if (position == 3) {
					rect.position.set(35, 37, 2)
					rect.rotation.set(0, Math.PI / 16, 0);
					rect.name = '5'
				}
				rect.visible = false
				builds.clickGroup.push(rect);
				builds.scene.add(rect)
			},
			clickVideo(index){
				let urlArr = [
					'https://open.ys7.com/v3/openlive/J94201253_1_1.m3u8?expire=1688737171&id=467082606131806208&t=06c1a2e792c5ade334b7645b0919202144d41c75b07fb90b1f81835e7fdb41fa&ev=100',
					'https://open.ys7.com/v3/openlive/J94201253_5_1.m3u8?expire=1688764287&id=467196339361427456&t=6be45250eb1c97576d1ba18299817668e60a9b4dd81578a5d44f2bb45ffc1508&ev=100',
					'https://open.ys7.com/v3/openlive/J94201253_3_1.m3u8?expire=1689096097&id=468588051015143424&t=ff398d452eb1f5caf2c6c3022a76bfe5385309c9bc072c98c84416535e4c2204&ev=100',
					'https://open.ys7.com/v3/openlive/J94201253_22_2.m3u8?expire=1689423397&id=469960848622751744&t=72be30e94a0818ddb7aa303c38b6fbd288da882fab9c05a1631cac4338941648&ev=100',
					// 'https://open.ys7.com/v3/openlive/J94201253_22_1.m3u8?expire=1689252602&id=469244482391375873&t=e937af4c2c80777c7d5b260e438d89861404ae3fca7e7fae75186257708083b2&ev=100',
					'https://open.ys7.com/v3/openlive/J94201253_8_1.m3u8?expire=1689251995&id=469241933890334720&t=c07fe6f262572e9ab7eb7060e280a4788dee76aff3787ec1598b29bd2ea8afbb&ev=100'
				]
				this.isShowVideo = true;
				let url = urlArr[(index) * 1 - 1]
				this.$nextTick(() => {
					if (Hls.isSupported()) {
						video = document.getElementById('my_video_1');
						if(video.src){
							hls.destroy()
						}
						hls = new Hls();
						hls.loadSource(url);
						hls.attachMedia(video);
						hls.on(Hls.Events.MANIFEST_PARSED, function(event,data) {
							video.play(hls);
						});
						hls.on(Hls.Events.ERROR, function (event, data) {
						  if (data.fatal) {
								 switch (data.type) {
									 case Hls.ErrorTypes.NETWORK_ERROR:
										 // try to recover network error
										 console.log('fatal network error encountered, try to recover');
										 hls.startLoad();
										 break;
									 case Hls.ErrorTypes.MEDIA_ERROR:
										 console.log('fatal media error encountered, try to recover');
										 hls.recoverMediaError();
										 break;
									 default:
										 // cannot recover
										 hls.destroy();
										 break;
								}
							}
						});
					}
				})
			},
			clickEvent() {
				// Guids.onMouseMove(event);
				// Guids.ClickUpdate((object) => {
				//   this.changeView(object.object.name);
				// });
				builds.onMouseMove(event);
				builds.ClickUpdate((object) => {
					// let urlArr = [
					// 	'https://open.ys7.com/v3/openlive/J94201253_1_1.m3u8?expire=1688737171&id=467082606131806208&t=06c1a2e792c5ade334b7645b0919202144d41c75b07fb90b1f81835e7fdb41fa&ev=100',
					// 	'https://open.ys7.com/v3/openlive/J94201253_5_1.m3u8?expire=1688764287&id=467196339361427456&t=6be45250eb1c97576d1ba18299817668e60a9b4dd81578a5d44f2bb45ffc1508&ev=100',
					// 	'https://open.ys7.com/v3/openlive/J94201253_3_1.m3u8?expire=1689096097&id=468588051015143424&t=ff398d452eb1f5caf2c6c3022a76bfe5385309c9bc072c98c84416535e4c2204&ev=100',
					// 	'https://open.ys7.com/v3/openlive/J94201253_22_2.m3u8?expire=1689423397&id=469960848622751744&t=72be30e94a0818ddb7aa303c38b6fbd288da882fab9c05a1631cac4338941648&ev=100',
					// 	// 'https://open.ys7.com/v3/openlive/J94201253_22_1.m3u8?expire=1689252602&id=469244482391375873&t=e937af4c2c80777c7d5b260e438d89861404ae3fca7e7fae75186257708083b2&ev=100',
					// 	'https://open.ys7.com/v3/openlive/J94201253_8_1.m3u8?expire=1689251995&id=469241933890334720&t=c07fe6f262572e9ab7eb7060e280a4788dee76aff3787ec1598b29bd2ea8afbb&ev=100'
					// ]
					// if (object.object.name == '1' || object.object.name == '2' || object.object.name == '3' ||
					// 	object.object.name == '4' || object.object.name == '5') {
					// 	this.isShowVideo = true;
					// 	let url = urlArr[(object.object.name) * 1 - 1]
					// 	this.$nextTick(() => {
					// 		if (Hls.isSupported()) {
					// 			video = document.getElementById('my_video_1');
					// 			if(video.src){
					// 				hls.destroy()
					// 			}
					// 			hls = new Hls();
					// 			hls.loadSource(url);
					// 			hls.attachMedia(video);
					// 			hls.on(Hls.Events.MANIFEST_PARSED, function() {
					// 				video.play();
					// 			});
					// 			hls.on(Hls.Events.ERROR, function (event, data) {
					// 			  if (data.fatal) {
					// 					 switch (data.type) {
					// 						 case Hls.ErrorTypes.NETWORK_ERROR:
					// 							 // try to recover network error
					// 							 console.log('fatal network error encountered, try to recover');
					// 							 hls.startLoad();
					// 							 break;
					// 						 case Hls.ErrorTypes.MEDIA_ERROR:
					// 							 console.log('fatal media error encountered, try to recover');
					// 							 hls.recoverMediaError();
					// 							 break;
					// 						 default:
					// 							 // cannot recover
					// 							 hls.destroy();
					// 							 break;
					// 					}
					// 				}
					// 			});
					// 		}
					// 	})
					// }

				});
			},
			dblClick() {
				builds.onMouseMove(event);
				builds.ClickUpdate((object) => {
					// console.log(object.object);
				});
			},
			initSky() {
				sky1 = builds.loadSkyBox("./building/sky_day/", "jpg");
				sky2 = builds.loadSkyBox("./building/sky_night/", "jpg");
				builds.scene.background = sky1;
				builds.scene.environment = sky1;
			},
			changeFloor(type) {
				if (type == 1) {
					//返回全景按钮
					this.backHomeFlag = false
					this.backHomePage("isRoundInto");
					this.backHomeFlag = false;
					// cameraModel.model.visible = false;
					// cameraModel2.model.visible = false;
					this.hideRoundFlag = false

				} else if (type == 2) {
					// // 查看构造		  
					// builds.outlinePass.selectedObjects = [];
					InnerBuilding.model.visible = true;
					mainBuilding.model.visible = false;
					this.backHomeFlag = true
					this.buildFoolerCon = true;
					this.changeCompassCamera("西");
				}
				// console.log(builds)
				this.isShowVideo = false
			},
			hideRound() {
				// builds.outlinePass.selectedObjects = [];
				if (this.hideRoundFlag == false) {
					if (mainBuilding.model.visible == true) {
						// 隐藏环境主楼楼展示
						InnerBuilding.model.visible = false;
						mainBuilding.model.visible = true;
					} else {
						//隐藏环境结构楼展
						InnerBuilding.model.visible = true;
						mainBuilding.model.visible = false;
					}
					terrain.model.visible = false;
					plane.model.visible = true;
					tree.model.visible = false;
					lightsd.model.visible = true;
					this.hideRoundFlag = true;
				} else {
					terrain.model.visible = true;
					plane.model.visible = false;
					tree.model.visible = true;
					lightsd.model.visible = true;
					if (mainBuilding.model.visible == false) {
						//环境结构楼展示
						InnerBuilding.model.visible = true;
						mainBuilding.model.visible = false;
					} else {
						//环境主楼楼展示
						InnerBuilding.model.visible = false;
						mainBuilding.model.visible = true;
					}
					this.hideRoundFlag = false;
				}
				// wall.model.visible = false;
				// wallOut.visible = false;
				// wallLeft.visible = false;
				// wallRight.visible = false;
			},
			backHomePage(type = "none") {
				open = true
				this.showFloor()
				if(hls&&video){
					if(video.src){
						hls.destroy()
					}
				}
				// hls.destroy();
				this.numFlag = false;
				this.isNumWork = true;
				InnerBuilding.model.visible = false;
				mainBuilding.model.visible = true;
				terrain.model.visible = true;
				tree.model.visible = true;
				lightsd.model.visible = true;
				plane.model.visible = false;
				Crane.model.visible = false;
				// cameraModel.model.visible = false;
				// cameraModel2.model.visible = false;
				this.isShowVideo = false
				if (type == "isRoundInto") {
					this.buildFoolerCon = false;
				} else {
					this.buildFoolerCon = false;
					this.mainContr = false;
					this.backHomeFlag = false;
				}
				builds.scene.traverse((child) => {
					if (child.isMesh) {
						if (child.name == '1' || child.name == '2' || child.name == '3' || child.name == '4' ||
							child.name == '5') {
							child.visible = false
						}
					}
				})
				// wall.model.visible = false;
				// wallOut.visible = false;
				// wallLeft.visible = false;
				// wallRight.visible = false;
				this.changeCompassCamera("西");
			},
			visibleBuilding(objects, visible = true) {
				for (let s = 0, t = objects.length; s < t; s++) {
					const op = {
						x: objects[s].defaultOpacity
					};
					let tovalue;
					if (visible == true) {
						tovalue = objects[s].defaultOpacity;
					} else {
						tovalue = 0;
					}
					const twa = new TWEEN.Tween(op);
					twa.to({
						x: tovalue
					}, 1000);
					twa.onUpdate(() => {
						objects[s].mat.transparent = true;
						objects[s].mat.opacity = op.x;
					});
					twa.start();
				}
			},
			showFloor() {
				// builds.outlinePass.selectedObjects = [];
				// console.log(builds.composer);
				if (InnerBuilding.model.visible == true) {
					InnerBuilding.model.traverse((obj) => {
						if (obj.isMesh) {
							obj.visible = true;
						}
					});
					if (open == true) {
						this.startShow(floorArray, final, start);
						open = false;
					} else if (open == false) {
						this.startShow(floorArray, start, final);
						open = true;
					}
				}
			},
			startShow(array, startValue, finalValue) {
				for (let s = 0, t = array.length; s < t; s++) {
					const value = {
						x: startValue
					};
					const tw1 = new TWEEN.Tween(value);
					tw1.to({
						x: finalValue
					}, 1000);
					tw1.onUpdate(() => {
						if (s != 0) {
							array[s].position.y = array[s - 1].position.y + value.x;
						}
					});
					tw1.start();
				}
			},
			//更换夜间模式
			changeSky() {
				if (this.sky == "night") {
					light.intensity = 1;
					builds.amblight.intensity = 0.5;
					directionalLight.intensity = 1
					directionalLight.color = new THREE.Color(0xffffff)
					directionalLight.color.convertSRGBToLinear()
					builds.scene.background = sky1;
					builds.scene.environment = sky1;
					pointLight.map((value) => {
						// console.log(value);
						value.intensity = 0;
					});
					this.sky = "day";
				} else if (this.sky == "day") {
					light.intensity = 0.2;
					directionalLight.intensity = 1
					directionalLight.color = new THREE.Color(0xFFFFCC)
					directionalLight.color.convertSRGBToLinear()
					builds.amblight.intensity = 0.3
					builds.scene.background = sky2;
					builds.scene.environment = sky2;
					pointLight.map((value) => {
						// console.log(value);
						value.intensity = 1;
					});
					this.sky = "night";
				}
			},
			//相机聚焦楼层
			focusFloor(value) {
				// floorArray
				// if (builds.outlinePass.selectedObjects.length > 0) {
				// 	builds.outlinePass.selectedObjects = [];
				// }
				let floors;
				for (let i = 0; i < floorArray.length; i++) {
					if (floorArray[i].name.indexOf(value) > -1) {
						floorArray[i].visible = true;
						floors = floorArray[i];
						// console.log(floors);
						// window.floors
						// builds.outlinePass.selectedObjects.push(floors);
					} else {
						floorArray[i].visible = false;
					}
				}
				const num = 50;

				const pos = {
					x: builds.camera.position.x,
					y: builds.camera.position.y,
					z: builds.camera.position.z,
				};
				const tws = new TWEEN.Tween(pos);
				tws.to({
						x: floors.position.x / num,
						y: floors.position.y / num,
						z: floors.position.z / num + 100,
					},
					500
				);
				tws.onUpdate(() => {
					builds.camera.position.set(pos.x, pos.y, pos.z);
					builds.control.target = builds.vec3(
						floors.position.x / num,
						floors.position.y / num,
						floors.position.z / num
					);
					builds.camera.lookAt(
						floors.position.x / num,
						floors.position.y / num,
						floors.position.z / num
					);
				});
				tws.start();
				this.changeCompassCamera("西");
			},
			changeCompassCamera(key) {
				switch (key) {
					case "东":
						builds.flyTo({
							position: [0, 81, -68],
							control: [0, 0, 0],
						});
						break;
					case "南":
						builds.flyTo({
							position: [87, 60, 1.02],
							control: [0, 0, 0],
						});
						break;
					case "西":
						builds.flyTo({
							position: [-1, 80, 68],
							control: [0, 0, 0],
						});
						break;
					case "北":
						builds.flyTo({
							position: [-81, 67, 2.5],
							control: [0, 0, 0],
						});
						break;
				}
			},
		},
		destroyed() {
			window.removeEventListener("click", this.clickEvent, false);
			window.removeEventListener("resize", this.resizes, false);
			builds.destroy();
			Guids.destroy();
			try{
				if(hls&&video){
					if(video.src){
						hls.destroy()
					}
				}
			}catch(err){
				
			}
		},
		mounted() {
			//Guids = new Tjs("guid");

			builds = new Tjs("build");
			// builds.axes();
			window.addEventListener("resize", this.resizes, false);
			window.builds = builds;
			//Guids.control.enabled = false;
			builds.control.maxPolarAngle = Math.PI / 2.2;
			builds.renders.outputEncoding = THREE.sRGBEncoding;
			builds.renders.gammaOutput = true;
			builds.renders.gammaFactor = 2.2;
			//builds.renders.logarithmicDepthBuffer = true;
			light = builds.addLight("direction", 0xffffff, 1);
			light.color.convertSRGBToLinear()
			light.position.set(100, 100, 100);
			
			directionalLight = new THREE.DirectionalLight(0xffffff,1,100)
			directionalLight.color.convertSRGBToLinear()
			directionalLight.castShadow = true;
			builds.scene.add(directionalLight);
			directionalLight.shadow.mapSize.width = 512;
			directionalLight.shadow.mapSize.height = 512;
			directionalLight.shadow.camera.near = 0.5;
			directionalLight.shadow.camera.far = 500;
			builds.scene.add(light);
			builds.camera.position.set(59, 47, 79);
			// builds.usePass();
			// builds.useRenderPass();
			// builds.useOutLine();
			// builds.useFXAA();

			builds.update = () => {
				// Guids.camera.rotation.copy(builds.camera.rotation);
				// const position = {
				//   x: builds.camera.position.x - builds.control.target.x,
				//   y: builds.camera.position.y - builds.control.target.y,
				//   z: builds.camera.position.z - builds.control.target.z
				// };
				// const distance = builds.camera.position.distanceTo(builds.control.target);
				// Guids.camera.position.set(
				//   (position.x / distance) * 20,
				//   (position.y / distance) * 20,
				//   (position.z / distance) * 20
				// );
				TWEEN.update();
			};

			this.initValue();

			//Guids.clickMode();
			builds.clickMode();
			this.initSky();
			this.sceneModel();
			window.addEventListener("click", this.clickEvent, false);
			// window.addEventListener('mousewheel',function(e){
			// },false)
		},
	};
</script>
<style lang="less" scoped>
	.wrapper {
		width: 100%;
		height: 100%;
	}

	#build {
		width: 100%;
		height: 100%;
	}

	#guid {
		position: absolute;
		width: 20%;
		height: 20%;
	}

	.but {
		position: absolute;
		top: 0%;
		right: 0%;
		z-index: 200;
	}

	#compass {
		position: absolute;
		width: 150px;
		height: 150px;
		bottom: 40px;
		right: 5%;
		background: url("./../assets/round.png");
		background-size: 100% 100%;

		#east {
			position: absolute;
			width: 30px;
			height: 30px;
			top: 58px;
			left: 116px;
			cursor: pointer;
		}

		#south {
			position: absolute;
			width: 30px;
			height: 30px;
			top: 126px;
			left: 60px;
			cursor: pointer;
		}

		#west {
			position: absolute;
			width: 30px;
			height: 30px;
			top: 62px;
			left: 1px;
			cursor: pointer;
		}

		#north {
			position: absolute;
			width: 30px;
			height: 30px;
			top: 0px;
			left: 60px;
			cursor: pointer;
		}
	}

	.mainBoxInfo {
		width: 100%;
		height: 100%;
	}

	.mainContr {
		text-align: right;
		padding-right: 60px;
		box-sizing: border-box;
	}

	.mainContr>div {
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		margin-left: 12px;
	}

	.controlBox {
		position: fixed;
		right: 0;
		top: 0;
		background-image: url("../assets/images/infoBg.png");
		width: 100%;
		height: 76px;
	}

	.controlBox>div {
		display: inline-block;
		vertical-align: middle;
		margin-top: 20px;
		color: #fff;
	}

	.homePageContr {
		position: fixed;
		width: 110px;
		top: 120px;
		right: 0;
		color: #fff;
		padding-right: 20px;

		div {
			cursor: pointer;
		}
	}
	.homePageControl{
		width: 170px!important;
		.floorCut{
			margin-top:210px!important;
		}
		.floorCeng>div{
			width:148px!important;
		}
	}
	.titleBox {
		padding-top: 2px;
		padding-bottom: 4px;
		padding-left: 10px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		font-size: 13px;

		img {
			vertical-align: middle;
		}

		span {
			margin-left: 8px;
			display: inline-block;
			vertical-align: middle;
			height: 16px;
			line-height: 16px;
			color: rgba(255, 255, 255, 0.7);
		}
	}

	.infoListBox {
		margin-top: 6px;

		span {
			display: inline-block;
			vertical-align: middle;
			font-size: 13px;
		}

		.cle {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background: #0076a3;
			margin-left: 10px;

			i {
				color: rgba(255, 255, 255, 0.7);
				margin-left: 4px;
			}
		}

		.discTitle {
			margin-left: 6px;
			color: rgba(255, 255, 255, 0.8);
		}

		.dis {
			margin-left: 6px;
			color: #fff;
		}
	}

	.floorCut {
		text-align: right;

		margin-top: 30px;

		.floorCeng {
			padding-left: 34px;
		}

		.floorCeng>div {
			margin-top: 10px;
			width: 80px;
			height: 34px;
			line-height: 34px;
			border-radius: 6px;
			background: url("../assets/images/infoBg.png");
			text-align: center;
		}
	}

	.floorCut>p {
		width: 40px;
		height: 34px;
		margin-left: 54px !important;
		text-align: center;
		background: #0089d1;
		border-radius: 4px;
		cursor: pointer;
	}

	// 视频进度条相关

	.pressDowm {
		width: calc(100% - 8px);
		height: 8px;
		border-radius: 6px;
		background: transparent;
		border: 1px solid rgba(255, 255, 255, 0.3);

		z-index: 5000;
		position: absolute;
		left: 4px;
		bottom: 30px;
	}

	.pressUp {
		width: 0;
		height: 8px;
		background: #fff;
		border-radius: 6px;
		z-index: 6000;
		position: absolute;
		left: 4px;
		bottom: 30px;
	}

	.point {
		width: 14px;
		height: 14px;
		border-radius: 50%;
		background-color: #0089d1;
		position: absolute;
		top: -14px;
		z-index: 5500;
		left: 30px;
		bottom: 30px;
	}

	//主页图片
	.homePageContr .backHome {
		height: 36px;
		width: 120px;
		/* vertical-align: middle; */
		border-radius: 4px;
		padding-top: 4px;
		box-sizing: border-box;
		padding-left: 10px;
		cursor: pointer;
		background-image: url("../assets/images/infoBg.png");

		span {
			display: inline-block;
			vertical-align: middle;
		}

		span:last-child {
			margin-left: 12px;
		}

		img {
			width: 26px;
			height: 26px;
			vertical-align: middle;
		}

		.homeIcon {
			background-image: url("../assets/images/home.svg");
			width: 26px;
			height: 26px;
		}
	}

	.mediaContainer .backHome {
		height: 36px;
		width: 120px;
		/* vertical-align: middle; */
		border-radius: 4px;
		padding-top: 4px;
		box-sizing: border-box;
		padding-left: 10px;
		cursor: pointer;
		background-image: url("../assets/images/infoBg.png");
	}

	.firstLeft {
		width: 222px;
		height: 500px;
		position: absolute;
		top: calc(50% - 250px);
		left: 20px;

		.info1,
		.info2 {
			position: relative;
		}

		.info1 {
			border-radius: 6px;
			height: 154px;
			width: 240px;
			background-image: url("../assets/images/infoBg.png");
		}

		.info2 {
			border-radius: 6px;
			margin-top: 18px;
			height: 222px;
			width: 240px;
			background-image: url("../assets/images/infoBg.png");

			.content {
				width: 240px;
				height: 190px;
				overflow: auto;
				padding: 10px 16px;
				box-sizing: border-box;
				text-align: justify;
				color: #fff;
				font-size: 12px;
				line-height: 18px;
			}
		}
	}

	.firstRight {
		width: 150px;
		height: 142px;
		position: absolute;
		top: calc(36% - 92px);
		right: 40px;
		text-align: center;

		.btn {
			width: 120px;
			height: 30px;
			line-height: 30px;
			background-image: url("../assets/images/infoBg.png");
			background-size: 140px 30px;
			color: #fff;
			border-radius: 3px;
			margin-top: 12px;
			cursor: pointer;
			box-shadow: 0px 0px 22px -1px rgba(0, 0, 0, 0.3);
		}
	}

	::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 1px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 1px;
	}

	::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		background: transparent;
	}

	::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		background: transparent;
	}

	.videoWorkBox {
		position: absolute;
		position: absolute;
		top: 7%;
		left: 4%;
		width: 400px;
		height: 276px;
		z-index: 9999;
		align-items: center;
		display: flex;
		background-image: url("../assets/images/infoBg.png");

		.videoPlayer {
			width: 400px;
			// background: #000;
		}
	}
	.loading{
		width:100%;
		height:100%;
		position: absolute;
		top:0;
		left:0;
		z-index:400000;
		background:rgba(0,0,0,0.3);
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@keyframes drehen {
	  0% {
	    /*动画起始为0*/
	    transform: rotate(0deg);
	  }
	  100% {
	    /*旋转了360度*/
	    transform: rotate(360deg);
	  }
	}
	
	.loadImg {
	  /*给之前的CSS加上动画效果 添加旋转动画 无限旋转*/
	  animation: drehen 0.8s infinite linear;
	}
</style>
